import { Requests } from '@config/request';
import {
  BRAND_SAAS_DOMAIN_URL,
  IP_URL_ENDPOINT,
  PASSIO_API_URL,
} from '@constants/system';
import axios from 'axios';
import { LocationResultDTO2 } from '../model/location';
import { IProfileHost, IProfileOutput } from '../model/profile';

const PROFILE_API = `${BRAND_SAAS_DOMAIN_URL}/api/account/me`;
const PROFILE_HOST_API = `${PASSIO_API_URL}/api/account/me`;
const PROFILE_AGENCY_API = `${BRAND_SAAS_DOMAIN_URL}/api/account/agency/me`;

export const getProfile = async () => {
  const res = await Requests.get<null, IProfileOutput>(PROFILE_API);
  return res;
};

export const getLocation = async () => {
  try {
    const res = await axios.get<null, { data: LocationResultDTO2 }>(IP_URL_ENDPOINT, {
      timeout: 4000,
    });
    return res.data;
  } catch (error) {
    console.log(error, 'authApi - getLocation');
  }
};

export const getProfileHost = async () => {
  const res = await Requests.get<null, IProfileHost>(PROFILE_HOST_API);
  return res;
};

export const getProfileAgency = async () => {
  const res = await Requests.get<null, IProfileHost>(PROFILE_AGENCY_API);
  return res;
};
